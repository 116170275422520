.alerts-app {
  margin: auto;
  // NOTE(abby): The content width shold be 1224 with 36px padding on the
  // left and right.
  max-width: calc(1224px + 2 * #{$space-xl-px});
  padding: $space-xl-px;

  &__tab {
    background: $white;
    border-radius: 5px;
    box-shadow: 0 5px 20px 0 rgba(35, 36, 37, 0.15);
    padding: 30px;
  }

  &__new-alert-btn-container {
    float: left;
    margin-bottom: 15px;
  }

  &__table-frequency-header {
    // NOTE(abby): Keep the header from wrapping
    min-width: 135px;
  }

  &__definition-table-action-column {
    min-width: 40px;
    text-align: right;
  }

  &__notification-table-action-column {
    color: #757578;
    min-width: 80px;
    text-align: right;
  }

  &__table-action-icon {
    margin: 2px;
  }
}

.alert-definition-modal {
  .zen-modal-header {
    background-color: $gray-light-active;
  }

  // NOTE(abby): This modal has a lot of different components that all need to
  // look the same. Therefore, there is a lot of overriding default behavior.

  &__heading {
    font-size: 16px;
    font-weight: 500px;
    line-height: 22px;
  }

  &__dropdown {
    height: 36px;
  }

  &__title {
    border-bottom: 1px solid #dfe1e6;
    font-size: 28px;
    line-height: $space-xl-px;
  }

  &__condition-section {
    border: 1px solid $gray;
    border-radius: $space-xxs-px;
  }

  &__condition-heading {
    color: $slate-hover;
  }

  &__condition-number {
    border: 1px solid $gray-light-active;
    border-radius: $space-xxs-px;
    font-family: monospace;
    font-size: 10px;
    height: $space-l-px;
    line-height: $space-l-px;
    margin-bottom: 6px;
    text-align: center;
    width: $space-l-px;
  }

  &__indicator-dropdown {
    border-color: $gray-light-active;
    display: block;
    height: $space-xl-px;
    // NOTE(abby): This is to align text with dropdown and input components.
    padding-left: 0.667em;
    padding-right: $space-m-px;
    width: 242px;

    &:hover {
      background-color: $gray-light;
      border-color: $gray;
    }

    &--error {
      border-color: $error-color;

      &:hover {
        border-color: $error-color;
      }
    }
  }

  &__number-input {
    border-color: $gray-light-active;
    box-shadow: none;
    height: 36px;

    &:hover {
      border-color: $gray;
    }

    &:focus {
      border-color: #00b8d6;
    }

    &::placeholder {
      font-size: 15px;
    }
  }

  &__column {
    // NOTE(abby): Each column here should always take up 1/3 of the space.
    // The padding in between is 16px.
    width: calc((100% - 32px) / 3);
  }

  &__groupby-dropdown {
    .query-part-selector {
      border-color: $gray-light-active;

      &:hover {
        background-color: $gray-light;
        border-color: $gray;
      }
    }

    &--error {
      .query-part-selector {
        border-color: $error-color;

        &:hover {
          border-color: $error-color;
        }
      }
    }
  }

  &__groupby-dropdown-button {
    height: $button-height;
    padding: 0px $space-xs-px;
  }

  &__groupby-dropdown-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__filters-dropdown--disabled {
    background-color: $gray-light-active;
    color: $gray;

    &:hover {
      background-color: $gray-light-active;
      border-color: $gray-light-active;
    }
  }
}

.create-alert-error-text {
  color: #dc143c;
}
