// height and width breakpoints before the page starts scrolling.
$min-page-height: 600px;
$min-page-width: 780px;
// any item within the query view (aqt-query-form-panel, live-results-view) will
// need a minimum height to correctly fill the page when we hit our breakpoints
// and start scrolling. In order to do that, we need to account for the height
// of the nav bar and the query tab list.
$query-view-items-min-height: $min-page-height - $navbar-height -
  $aqt-query-tab-list-height;
$query-view-items-min-height-without-tabs: $min-page-height - $navbar-height;

$highlight-color: #5545e4;

$aqt-query-form-panel-width: 300px;
// NOTE(nina): We define a seperate class from advanced-query-app because we
// want to introduce horizontal and vertical breakpoints specifically to the
// AQT. In order to do that, we need to define a class at the body level
// (which is above advanced-query-app) with those breakpoints that we can then
// pass into the AQT template.

.advanced-query-app-body {
  min-height: $min-page-height;
  min-width: $min-page-width;
  overflow-x: auto;
}

.advanced-query-app {
  background-color: $site-background;
  color: $aqt-base-font-color;
  display: flex;
  flex-direction: column;

  // NOTE(david): Setting position: relative to allow query tool popovers to use
  // the root app as a parent element. The current use case for this is for
  // rendering them in the dashboard query edit view, which is a modal itself.
  position: relative;
  width: 100%;

  &__main-column {
    /* stylelint-disable-next-line max-line-length */
    display: inline-block;
    height: $aqt-query-form-panel-height;
    min-height: $query-view-items-min-height;
    vertical-align: top;

    &--disabled-tabs {
      height: $aqt-query-form-panel-height-without-tabs;
      min-height: $query-view-items-min-height-without-tabs;
    }
  }

  &__query-view {
    display: flex;
    flex: 1;
    flex-direction: row;
  }
}

.live-results-view {
  display: grid;
  flex: 1;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  justify-content: center;
  overflow: hidden;
  z-index: 0;
}

.aqt-query-container{
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-grow: 1;
  overflow: hidden;
}

.aqt-ai-panel-container {
  flex: 0 0 400px;
  overflow: hidden auto;
  padding: 24px 10px 30px 10px;
  z-index: $aqt-query-form-z-index;
}

.ai-dropdown-menu-button {
  align-items: center;
  background: none;
  border: none;
  color: inherit;
  display: flex;
  font-size: 15px;
  height: 100%;
  padding: 19px 15px;
  padding-left: 25px;
  width: 100%;

  @media (max-width: 1250px) {
    padding-left: $space-s-px;
    padding-right: $space-s-px;
  }

  // Needed to stop resizing on click
  .zen-dropdown-button__button-content {
    margin-right: 0;
  }

  &--disabled {
    cursor: pointer;
    opacity: 0.5;

    &:hover {
      background-color: $gray-light;
      border: none;
    }
  }

  &--enabled {
    &:hover {
      background-color: $gray-light-hover;
      border: none;
    }
  }

  &__icon {
    font-size: 20px;
    vertical-align: bottom;
    &--highlighted {
      color: $highlight-color
    }
  }

  &__text {
    margin-left: $space-xs-px;
    &--highlighted {
      color: $highlight-color
    }
  }
}

.aqt-ai-button-disabled-wrapper{
  display: inline-block;
}

.aqt-ai-dropdown{
  $border-width: 8px;
  $border-bottom-width: 12px;
  border: none;
  border-radius: $navbar-drop-down-border-radius;
  box-shadow: 3px 5px 15px 0 rgba(0, 0, 0, 0.2);
  line-height: 40px;
  margin-top: -4px;
  max-height: 90vh;
  padding: 20px;
  width: 75%;

  &__secondary {
    padding: 0;
  }

  .dropdown-menu-title{
    background: $white;
    color: $black;
    cursor: default;
    font-size: 14px;
    overflow: hidden;
    padding-bottom: 8px; 
    padding-left: 0px;
    padding-top: 0px;
  }

  .dropdown-menu-open-ai-analysis{
    align-self: flex-end;
    color: #5196de;
    text-decoration: underline;
  }

  .main-options {
    border: 1px solid $aqt-base-border-color;
    border-radius: 5px;
    margin-bottom: 12px;
    &__text{
      line-height: 1.2;
      white-space: normal;
      .u-info-text{
        color: #58585a;
      }
    }
  }
}

.aqt-ai-panel-background {
  align-items: center;
  background: 
    radial-gradient(50% 65% ellipse at left,#fff1db 50%, transparent), 
    radial-gradient(50% 59.2% ellipse at right, #d8cefa 50%, transparent);
  background-color: #ffd0fc;
  border-radius: $aqt-query-result-border-radius;
  // shadow intentionally added twice according to design spec
  box-shadow: 0 5px 20px 0 rgba(35, 36, 37, 0.15),
    0 5px 20px 0 rgba(35, 36, 37, 0.15);
  height: 100%;
  overflow: hidden auto;
  position: relative;
  z-index: $aqt-query-form-z-index;
}

.aqt-ai-panel {
  backdrop-filter: blur(50px);
  height: 100%;
  &__header {
    align-items: center;
    background: 
        linear-gradient(to right, #fbe6e7 0%, #ffd0fc 50%, #e1cff9 100%);
    border-top-left-radius: $aqt-query-result-border-radius;
    border-top-right-radius: $aqt-query-result-border-radius;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
    // NOTE(pablo): use clip-path to only show the bottom shadow and hide the
    // horizontal parts of the shadow
    clip-path: inset(0px 0px -5px 0px);
    display: flex;
    flex-basis: $visualization-picker-control-bar-height;
    flex-direction: 'column';
    height: $visualization-picker-control-bar-height;
    justify-content: space-between;
    position: sticky;
    top: 0; 
    width: 100%;
    z-index: 1;
  }

  &__body {
    margin-left: 20px;
    margin-right: 20px;
  }

  &__content {
    background:$white;
    border:1px solid $aqt-base-border-color;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 20px;
    padding: 12px;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  &__copy-button {
    background-color: #ebecee;
    border-radius: 5px;
    height: 32px;
    margin-bottom: 12px;
    margin-top: 6px;
    width: 60px;
  }

  &__prompt {
    background: rgba(255, 255, 255,0.32);  
    border:1px solid $aqt-base-border-color;
    border-radius: 8px;
    box-sizing: border-box;
    cursor: default;
    line-height: 1.2;
    margin-bottom: 8px;
    padding-left: 8px;
    padding-top: 8px;
    text-align: left;
    white-space: normal;
    width: 100%;

    .u-paragraph-text{
      font-size: 14px;
      line-height: 16px;
    }

    .u-info-text{
      color: #58585a;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.aqt-query-form-panel {
  flex: 0 0 $aqt-query-form-panel-width;
  overflow: hidden auto;
  padding: 10px 30px 0px 30px;
  z-index: $aqt-query-form-z-index;

  &__closed{
    min-height: calc(
      100vh - #{$navbar-height} - #{$aqt-query-tab-list-height}
    )
  }

  &__open{
    background-color: rgba(255, 255, 255, 0.79);
    bottom: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  &__insights-pane {
    flex: 0 0 320px;
    padding: 10px 20px 0px 20px;
  }

  &__title {
    color: $slate;
    padding-top: 15px;
  }

  &__share-query {
    margin-bottom: 40px;
    text-align: center;
  }

  &__insights-count-badge {
    $badge-size: 20px;

    align-items: center;
    background-color: $warning-color;
    background-image: $warning-color-gradient;
    border: 2px solid $white;
    border-radius: 50%;
    color: $white;
    display: flex;
    flex-direction: column;
    font-size: $font-s;
    height: $badge-size;
    position: absolute;
    right: -#{$badge-size + 2px};
    width: $badge-size;

    @include zen-elevation-4();
  }

  &__tab-header-popover {
    font-size: $font-m;
  }

  // zen tabs customization in order to display
  // one tab left aligned and one right aligned
  .zen-tabs__header-row {
    position: relative;
  }

  .zen-tab-header__outer-container:first-child {
    flex: 1;
  }
}

.query-part-selector {
  background-color: $query-part-selector-bg-color;
  border: 1px solid $aqt-base-border-color;
  border-radius: 5px;

  &__add-btn {
    cursor: pointer;
    padding: 3px;
    width: 100%;
    &:hover {
      background-color: $query-part-selector-add-btn-hover;
    }
  }

  &__add-btn-contents {
    display: table;
    margin: 0 auto;
  }

  &__btn-icon {
    font-size: 12px;
    margin-left: 3px;
  }
}

.selection-block {
  margin-bottom: 40px;

  &:first-child {
    margin-top: 40px;
  }

  &__title-block {
    align-items: center;
    color: $slate;
    display: flex;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    justify-content: space-between;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 5px;
  }
}

.series-element {
  display: inline-block;

  &__order {
    display: inline-block;
    margin-right: 15px;
  }

  &__visibility {
    display: inline-block;
    margin-right: 15px;
  }

  &__input-text {
    display: inline-block;
    // TODO(nina): rethink how we define this width
    width: 75%;
  }
  &__icon {
    display: inline-block;
    float: right;
    margin-top: 5px;
  }
}

.compact-title-block {
  padding: 10px 10px 20px 10px;

  // TODO(nina): might need to reconsider widths

  &__font-size-title-label {
    line-height: 18px !important;
    margin-top: 10px !important;
    width: 50%;
  }

  &__font-size-subtitle-label {
    line-height: 18px !important;
    margin-top: 10px !important;
    width: 45%;
  }

  &__title-control {
    margin-top: 10px !important;
  }

  &__subtitle-control {
    margin-top: 10px !important;
  }
}
.aqt-query-result-container {
  align-items: center;
  display: flex;
  flex: 1;
  grid-row: 2;
  justify-content: center;
  overflow: hidden;
  padding: $aqt-query-result-container-spacing;

  &__scaled-container {
    align-items: center;
    border-radius: $aqt-query-result-border-radius;

    // shadow intentionally added twice according to design spec
    box-shadow: 0 5px 20px 0 rgba(35, 36, 37, 0.15),
      0 5px 20px 0 rgba(35, 36, 37, 0.15);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    position: relative;

    .visualization-container {
      border-bottom-left-radius: $spacing-xs;
      border-bottom-right-radius: $spacing-xs;

      .title-extra-fields {
        font-style: italic;
      }
    }
  }

  &__query-result {
    background-color: $white;
    height: calc(100% - #{$visualization-picker-control-bar-height});
    position: relative;
    width: 100%;
    // Ensure the query result is below the viz picker when stacking.
    z-index: 0;
    // HACK(nina): We don't want the padding of this container to change at
    // any screen resolution, so we add this to offset the padding-top property
    // in 'mobile mode', as the query result in AQT uses both those this class
    // and the class that is built into the QueryResult component.
    // Unfortunately, we also have to add the !important identifier because
    // this class is applied first, so it's padding-top value will be overriden
    // otherwise. In the future, I think we should get rid of these features
    // for 'mobile mode' altogether the more we refine our scaling treatment.
    // We should also not use screen width to solely determine mobile mode.
    @media (max-width: 940px) {
      padding-top: 15px !important;
    }
  }
}

.query-result__aqt-action-buttons {
  background-color: #f9f9f9;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  padding: 6px 10px 3px 10px;
}

.aqt-sharing-modal__copy-to-clipboard-interaction {
  padding: 10px;
  text-align: center;
}
