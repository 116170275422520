.tag-input-text-block {
  align-items: center;
  background-color: $white;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  flex-wrap: wrap;
  margin: 0 0.4em 0 0;
  min-height: 40px;
  padding: 6px 11px 6px 11px;
  transition: border-color 0.15s ease-in-out;
  width: 100%;

  /*
  Nesting this  to avoid TagInputText CSS being overriden by CSS
  defined in _smartadmin_overrides.scss for input elements
  */
  .tag-input-text-block__input {
    border: none;
    box-shadow: none;
    flex: 1;
    font-size: 15px;

    &:focus {
      border: 0;
      // add !important to avoid box-shadow being overriden by input styles;
      box-shadow: none !important;
      outline: none;
    }
  }
}
