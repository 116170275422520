.gd-scroll-to-top-button {
  border-radius: 50%;
  bottom: 20px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.7);
  cursor: pointer;
  height: 50px;
  padding: $space-m-px;
  position: fixed;
  right: 20px;
  width: 50px;
}
