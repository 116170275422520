@keyframes card-highlighted-animation {
  0% {
    background-color: #f0edff;
  }

  100% {
    background-color: $white;
  }
}

.data-upload-matching-card {
  background-color: $white;
  border-bottom: 1px solid $gray-light-active;
  padding: $space-s-px 0;

  &--highlighted {
    animation: card-highlighted-animation 1200ms
      cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  &:last-of-type {
    margin-bottom: $space-s-px;
  }

  &__icon {
    height: 20px;
    // NOTE(abby): The text line height is an uneven number and it shouldn't
    // be vertically centered hence this awkward padding.
    padding-top: 5px;
  }

  &__error-state-icon {
    color: $error-color;
  }

  &__accept-state-icon {
    color: $success-color;
    height: 20px;
    width: 20px;
  }

  &__header--ignored {
    color: $gray;
    padding-left: $space-l-px;
  }

  &__dropdown-button {
    // TODO(abby, anyone): Once cards are draggable, this dropdown should not
    // be visible until hover. However, for now since it's the only way to
    // move them, it is more visible.
    border: none;
    border-radius: 100px;
    height: 28px;
    margin-right: $space-xs-px;
    padding-left: 10px;
    width: 28px;

    &:hover {
      background-color: $white-active;
      border: none;
    }
  }

  &__match-selector {
    display: flex;
    padding-top: $space-xxs-px;
    .query-part-selector {
      background: none;
      border: none;
    }
  }

  &__match-selector-label {
    color: $slate-hover;
    flex-shrink: 0;
    margin-right: $space-xxs-px;
  }

  &__match-selector-button {
    @include cohort-tag();
    padding-bottom: $space-xs-px;
  }

  &__message {
    &--ignored {
      color: $gray;
    }

    &--error {
      color: $error-color;
      padding-top: $space-xxs-px;
    }
  }

  &__matching-error-msg {
    padding-left: 76px;
  }

  &__new-column {
    color: $slate-hover;
  }
}
