$jarvis-padding: $font-size-base * 2;
$input-border-radius: 2px;

// Main Theme Values
$blueStandard: #3276b1;

//== IMAGE PATH & BACKGROUNDS
$base-url: '../images';

//== ASIDE WIDTH (NAVIGATION WIDTH & COLOR)
// careful when you are resizing this as it may affect lower resolutions
$asideWidth: 220px;
$asideMinifiedWidth: 0px;

$content-horizontal-padding: 14px;
$content-vertical-padding: 10px;
