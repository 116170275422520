.zen-table-container {
  margin-bottom: 5px;

  &--no-headers {
    margin-bottom: 0;

    // if there are no headers then we hide the border-bottom from the last row
    .zen-table__row:last-child {
      border-bottom: none;
    }
  }
}

.zen-table {
  background-color: $white;
  font-size: 15px;
  table-layout: fixed;
  width: 100%;

  &--auto-table-layout {
    table-layout: auto;
  }

  &--is-hoverable {
    .zen-table__row:hover {
      background-color: $white-hover;

      &.zen-table__row--is-selected {
        background-color: $white-active;
      }
    }
  }

  &__cell,
  &__header-cell {
    padding-bottom: 0.5em;
    padding-left: 0;
    padding-right: 1em;
    padding-top: 0.5em;

    &:last-child {
      padding-right: 0;
    }
  }

  &__header {
    border-bottom: 2px solid $slate;
  }

  &__header-cell {
    font-weight: 400;
    &--sortable {
      cursor: pointer;
    }
  }

  &__header-cell-content-container {
    align-items: center;
    display: flex;
    &--centered {
      justify-content: center;
    }
  }

  &__header-cell-content {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__header-cell-sort-carets {
    display: inline-block;
  }

  &__cell {
    padding-bottom: 1em;
    padding-top: 1em;
    word-wrap: break-word;
  }

  &__row {
    border-bottom: 1px solid $gray;

    &--is-clickable {
      cursor: pointer;
    }

    &--is-selected {
      background-color: $white-active;
    }

    &:last-child {
      border-bottom: 2px solid $slate;
    }
  }

  &__loading-spinner {
    text-align: center;
  }

  &__sort-caret-container {
    display: inline-block;
    margin-left: 5px;
    position: relative;
    top: 1px;

    .zen-caret {
      font-size: 9px;
    }
  }

  &__sort-caret {
    color: $gray;
    &--selected {
      color: $blue-primary;
    }
  }

  &__sort-caret-up {
    position: absolute;
    top: 0;
  }

  &__editable-row-actions {
    float: right;
  }

  &__edit-actions {
    align-items: center;
    display: flex;
  }

  &__edit-mode-button {
    align-items: center;
    border-radius: 50%;
    display: flex;
    height: 36px;
    justify-content: center;
    margin: 0 $space-xxs-px;
    width: 36px;
  }

  &__edit-button {
    fill: $slate;

    &:hover {
      background-color: rgba($gray-hover, 0.06);
    }
  }

  &__cancel-button {
    fill: $error-color;

    &:hover {
      background-color: rgba($error-color-hover, 0.1);
    }
  }

  &__save-button {
    fill: $success-color;

    &:hover {
      background-color: rgba($success-color-hover, 0.1);
    }
  }

  &__edit-mode-button-separator {
    background-color: $gray;
    height: 16px;
    width: 1px;
  }

  &__vertical-divider {
    align-items: center;
    border-right: 1px solid $gray;
    display: flex;
    height: 20px;
    padding-right: $space-xxs-px;
  }
}

.zen-table-search-container {
  float: right;
  margin-bottom: 10px;
}

.zen-table-action-selector-container {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

.zen-table-download-button {
  align-items: center;
  background-color: $white;
  border: 1px solid $gray;
  border-color: $gray;
  border-radius: 5px;
  display: flex;
  margin-right: 20px;

  .zen-icon {
    margin-right: 5px;
  }

  &:hover {
    background-color: $white-hover;
    border-color: $gray;
  }
}
