.zen-loading-spinner {
  animation: rotating 1s linear infinite;
  background: transparent;
  border: 2px solid;
  border-bottom-color: transparent !important;
  border-radius: 100%;
  display: inline-block;
}

.zen-ai-loading-spinner {
  border-radius: 50%;
  color: #aa81f3;
  height: 48px;
  perspective: 1000px;
  transform: rotateZ(45deg);
  width: 48px;
}
.zen-ai-loading-spinner:before,
.zen-ai-loading-spinner:after {
  animation: 1s spin linear infinite;
  border-radius: 50%;
  content: '';
  display: block;
  height: inherit;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotateX(70deg);
  width: inherit;
}

.zen-ai-loading-spinner:after {
  animation-delay: .4s;
  color: #cdacf2;
  transform: rotateY(70deg);
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: .2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: .2em .2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 .2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -.2em .2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -.2em -.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: .2em -.2em 0 0 currentcolor;
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
