@keyframes fallback-pill-wave {
  0% {
    transform: translateX(-100%);
  }
  60% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.fallback-pill {
  background: $gray-light-active;
  background-image: linear-gradient(
    to left,
    $gray-light-active 0%,
    $gray-light-hover 20%,
    $gray-light-active 40%,
    $gray-light-active 100%
  );
  background-repeat: no-repeat;
  background-size: 400px 100px;
  border-radius: 14px;
  overflow: hidden;
  position: relative;

  // The pill will animate to indicate that loading is happening.
  &:before {
    content: ' ';
  }

  &:after {
    animation: fallback-pill-wave 1s linear 0.5s infinite;
    background-image: linear-gradient(
      90deg,
      transparent,
      rgba(0, 0, 0, 0.04),
      transparent
    );
    background-repeat: no-repeat;
    background-size: 400px 100px;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(-100%);
  }
}
